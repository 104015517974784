import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const HeroContainer = styled.div`
  background: linear-gradient(135deg, #4c00aa, #00c3e6);
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding: 0 20px;
`;

const HeroTitle = styled.h1`
  color: white;
  font-size: 4rem;
  font-weight: bold;
  text-transform: uppercase;

  span {
    color: #00c3e6;
  }
`;

const HeroDescription = styled.p`
  color: white;
  font-size: 1.2rem;
  max-width: 600px;
  margin: 20px 0;
`;

const Button = styled.button`
  background-color: white;
  color: #4c00aa;
  padding: 10px 20px;
  font-size: 1rem;
  border: none;
  cursor: pointer;
  margin-top: 20px;
  border-radius: 25px;
  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: #00c3e6;
    color: white;
  }
`;

const HeroSection = () => {

  const navigate = useNavigate();

  const handleMoreInfoClick = () => {
    navigate('/services'); // Navigate to the "Services" page
  };

  return (
    <HeroContainer>
      <HeroTitle>
        technology <span>of the year</span>
      </HeroTitle>
      <HeroDescription>
        Empowering the Future with Cutting-Edge Technology. Innovating today for a smarter, more connected tomorrow.
      </HeroDescription>
      <Button onClick={handleMoreInfoClick}>More Info</Button>
    </HeroContainer>
  );
};

export default HeroSection;
