import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { doc, setDoc, getDoc } from 'firebase/firestore'; // Updated to include getDoc
import { onAuthStateChanged } from 'firebase/auth';
import { db, auth } from '../firebaseConfig'; // Firebase config for Firestore and Auth
import { FaCcVisa, FaCcMastercard, FaCcAmex } from 'react-icons/fa'; // Card icons

// Main container for payment page
const PaymentContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 40px;
  background-color: #f8f9fb;
  min-height: 100vh;
`;

// Form section
const FormSection = styled.div`
  width: 50%;
  background-color: #fff;
  border-radius: 15px;
  padding: 30px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

// Section Header
const SectionHeader = styled.h2`
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
  font-weight: bold;
  text-align: center;
`;

// Input field styles
const InputField = styled.input`
  width: 100%;
  padding: 15px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 16px;
  color: #333;
  box-sizing: border-box;

  &:focus {
    border-color: #6A00F4;
    outline: none;
    box-shadow: 0 0 8px rgba(106, 0, 244, 0.2);
  }

  &:disabled {
    background-color: #e0e0e0;
  }
`;

// Flex container for Expiration, CVV, and Postal Code on one line
const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 15px;
`;

// Smaller input fields for Expiration, CVV, and Postal Code
const SmallInputField = styled(InputField)`
  width: 100%; /* Adjusts to fit the flexbox layout */
`;

// Card icons container
const CardIconsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
`;

// Summary section
const Summary = styled.div`
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
`;

// Summary text
const SummaryText = styled.p`
  font-size: 18px;
  color: #333;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

// Total price text
const TotalPriceText = styled.h3`
  font-size: 22px;
  color: #333;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

// Pay button
const PayButton = styled.button`
  width: 100%;
  padding: 15px;
  background-color: #6A00F4;
  color: #fff;
  border: none;
  border-radius: 8px;
  font-size: 18px;
  cursor: pointer;
  &:hover {
    background-color: #4e00b8;
  }
  &:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
`;

const PaymentPage = () => {
  const [userId, setUserId] = useState(null);
  const [cardDetails, setCardDetails] = useState({
    cardholderName: '',
    cardNumber: '',
    expiration: '',
    cvv: '',
    postalCode: '',
    address: '',
    email: '',
  });
  const [isSubmitted, setIsSubmitted] = useState(false); // Disable form after submission
  const [discount] = useState(0.5); // 50% discount
  const courseCost = 3000.00;
  const discountedPrice = courseCost * (1 - discount);

  // Get authenticated user and check if the user has already paid
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setUserId(user.uid);
        const paymentRef = doc(db, 'payments', user.uid);
        const paymentSnap = await getDoc(paymentRef);

        if (paymentSnap.exists()) {
          const paymentData = paymentSnap.data();
          if (paymentData.isActive) {
            setCardDetails(paymentData); // Prepopulate fields with existing data
            setIsSubmitted(true); // Disable fields if payment is already made
          } else {
            setCardDetails(prevDetails => ({ ...prevDetails, email: user.email })); // Prepopulate email field if not paid
          }
        }
      }
    });
    return () => unsubscribe(); // Clean up listener
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCardDetails(prevDetails => ({
      ...prevDetails,
      [name]: value
    }));
  };

  const validateExpirationDate = (date) => {
    const expirationPattern = /^(0[1-9]|1[0-2])\/?([0-9]{2})$/; // MM/YY format
    const [month, year] = date.split('/');
    const expDate = new Date(`20${year}`, month);
    const currentDate = new Date();

    return expirationPattern.test(date) && expDate > currentDate;
  };

  const validateCardNumber = (number) => {
    const cardPattern = /^\d{16}$/; // Validates 16-digit card numbers
    return cardPattern.test(number);
  };

  const handlePay = async () => {
    // Check if all fields are filled
    const { cardholderName, address, email, postalCode, cardNumber, expiration, cvv } = cardDetails;
    if (!cardholderName || !address || !email || !postalCode || !cardNumber || !expiration || !cvv) {
      alert('Please fill all the fields');
      return;
    }

    if (!validateExpirationDate(cardDetails.expiration)) {
      alert('Invalid expiration date');
      return;
    }

    if (!validateCardNumber(cardDetails.cardNumber)) {
      alert('Invalid card number. Please enter a valid 16-digit card number.');
      return;
    }

    if (!userId) {
      alert('User not authenticated');
      return;
    }

    const paymentData = {
      userId,
      email: cardDetails.email,
      cardholderName: cardDetails.cardholderName,
      address: cardDetails.address,
      postalCode: cardDetails.postalCode,
      courseName: 'Data Engineer - Big Data',
      cost: courseCost,
      discount: discount * 100 + '%',
      finalCost: discountedPrice,
      timestamp: new Date(),
      isActive: true,
    };

    try {
      // Save payment data to Firestore
      await setDoc(doc(db, 'payments', userId), paymentData);
      alert('Payment successful and data saved');
      setIsSubmitted(true); // Disable form after submission
    } catch (error) {
      console.error('Error saving payment data: ', error);
      alert('Error processing payment');
    }
  };

  return (
    <PaymentContainer>
      <FormSection>
        <SectionHeader>Subscribe to Data Engineer</SectionHeader>

        {/* Cardholder's Full Name */}
        <InputField
          type="text"
          name="cardholderName"
          placeholder="Cardholder Name"
          value={cardDetails.cardholderName}
          onChange={handleChange}
          disabled={isSubmitted}
          required
        />

        {/* Card Number */}
        <InputField
          type="text"
          name="cardNumber"
          placeholder="Card Number"
          value={cardDetails.cardNumber}
          onChange={handleChange}
          disabled={isSubmitted}
          required
        />

        {/* Card Icons */}
        <CardIconsContainer>
          <FaCcVisa size={40} color="#1A1F71" />
          <FaCcMastercard size={40} color="#F79E1B" />
          <FaCcAmex size={40} color="#2E77BC" />
        </CardIconsContainer>

        {/* Address */}
        <InputField
          type="text"
          name="address"
          placeholder="Address"
          value={cardDetails.address}
          onChange={handleChange}
          disabled={isSubmitted}
          required
        />

        {/* Flex container for Expiration, CVV, and Postal Code */}
        <FlexContainer>
          <SmallInputField
            type="text"
            name="expiration"
            placeholder="Expiration (MM/YY)"
            value={cardDetails.expiration}
            onChange={handleChange}
            disabled={isSubmitted}
            required
          />

          <SmallInputField
            type="text"
            name="cvv"
            placeholder="CVV"
            value={cardDetails.cvv}
            onChange={handleChange}
            disabled={isSubmitted}
            required
          />

          <SmallInputField
            type="text"
            name="postalCode"
            placeholder="Postal Code"
            value={cardDetails.postalCode}
            onChange={handleChange}
            disabled={isSubmitted}
            required
          />
        </FlexContainer>

        {/* Email */}
        <InputField
          type="email"
          name="email"
          placeholder="Email"
          value={cardDetails.email}
          onChange={handleChange}
          disabled={isSubmitted}
          required
        />

        <Summary>
          <SummaryText>
            <span>Course Cost</span>
            <span>${courseCost.toFixed(2)}</span>
          </SummaryText>
          <SummaryText>
            <span>Discount</span>
            <span>- ${(courseCost * discount).toFixed(2)}</span>
          </SummaryText>
          <TotalPriceText>
            <span>Total</span>
            <span>${discountedPrice.toFixed(2)}</span>
          </TotalPriceText>
        </Summary>

        <PayButton onClick={handlePay} disabled={isSubmitted}>
          {isSubmitted ? 'Paid' : 'Pay'}
        </PayButton>
      </FormSection>
    </PaymentContainer>
  );
};

export default PaymentPage;
