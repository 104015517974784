import React, { useState } from "react";
import { signInWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../firebaseConfig";
import { useNavigate } from "react-router-dom";

const Login = ({ setIsAuthenticated }) => {  // Accept setIsAuthenticated as a prop
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [forgotPassword, setForgotPassword] = useState(false); // Track forgot password state
  const [resetEmail, setResetEmail] = useState(""); // Track email for password reset
  const [resetMessage, setResetMessage] = useState("");
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try { 
      await signInWithEmailAndPassword(auth, email, password);  // Firebase login
       // Update authentication state
      localStorage.setItem('isAuthenticated', 'true');  // Store login state in localStorage
      setIsAuthenticated(true); 
      navigate('/courses');  // Redirect to courses after login
    } catch (error) {
      setError('Login failed. Please check your email and password.');
    }
  };

  const handlePasswordReset = async (e) => {
    e.preventDefault();
    try {
      await sendPasswordResetEmail(auth, resetEmail);
      setResetMessage("Password reset email sent!");
    } catch (error) {
      setResetMessage("Failed to send reset email. Please try again.");
    }
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.logoText}><span>Black</span>Box Big Data</h1>
      <h2 style={styles.title}>Login</h2>
      {error && <p style={styles.errorText}>{error}</p>}

      {!forgotPassword ? (
        <form onSubmit={handleLogin} style={styles.form}>
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            style={styles.input}
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            style={styles.input}
          />

          <div style={styles.forgotButton}>
            <button 
              type="button" 
              onClick={() => setForgotPassword(true)} 
              style={styles.linkButton}
            >
              Forgot Password?
            </button>
          </div>

          <button type="submit" style={styles.loginButton}>Sign in</button>
        </form>
      ) : (
        <form onSubmit={handlePasswordReset} style={styles.form}>
          <input
            type="email"
            placeholder="Enter your email to reset password"
            value={resetEmail}
            onChange={(e) => setResetEmail(e.target.value)}
            required
            style={styles.input}
          />

          <button type="submit" style={styles.loginButton}>Send Reset Email</button>
          {resetMessage && <p style={styles.resetMessage}>{resetMessage}</p>}

          <button 
            type="button" 
            onClick={() => setForgotPassword(false)} 
            style={styles.linkButton}
          >
            Back to Login
          </button>
        </form>
      )}

      <div style={styles.registerContainer}>
        <p style={styles.registerText}>Don't have an account?</p>
        <a href="/signup" style={styles.registerLink}>Register for free</a>
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "100vh",
    background: "linear-gradient(135deg, #7f00ff, #00b4d8)", // Gradient background
    padding: "20px",
  },
  logoText: {
    fontSize: "32px",
    fontWeight: "bold",
    color: "#fff",
    marginBottom: "40px",
  },
  title: {
    fontSize: "24px",
    color: "#fff",
    marginBottom: "20px",
  },
  form: {
    width: "100%",
    maxWidth: "400px",
    display: "flex",
    flexDirection: "column",
    marginBottom: "20px",
  },
  input: {
    backgroundColor: "#fff",
    borderRadius: "10px",
    padding: "12px",
    marginBottom: "10px",
    fontSize: "16px",
    width: "100%",
    boxSizing: "border-box",
    border: "none",
  },
  forgotButton: {
    alignSelf: "flex-end",
    marginBottom: "20px",
  },
  forgotText: {
    color: "#f0f0f0",
    textDecoration: "none",
  },
  loginButton: {
    backgroundColor: "#673ab7", // Purple button color with similar tone
    padding: "15px 0",
    borderRadius: "10px",
    color: "#fff",
    fontSize: "18px",
    fontWeight: "bold",
    border: "none",
    cursor: "pointer",
    transition: "background-color 0.3s ease",
  },
  linkButton: {
    backgroundColor: 'transparent',
    border: 'none',
    color: '#673ab7',  // Same purple color as your links
    cursor: 'pointer',
    textDecoration: 'underline',
    fontSize: 'inherit',
  },
  registerContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  registerText: {
    color: "#f0f0f0",
  },
  registerLink: {
    color: "#673ab7", // Purple link color
    marginLeft: "5px",
    textDecoration: "none",
  },
  errorText: {
    color: "red",
    marginBottom: "10px",
  },
  resetMessage: {
    color: "green",
    textAlign: "center",
    marginTop: "10px",
  },
};

export default Login;
