import React, { useState } from 'react';
import { getAuth, updatePassword, reauthenticateWithCredential, EmailAuthProvider } from 'firebase/auth';
import '../assets/ChangePassword.css'; // Use the same style as the login

const ChangePassword = () => {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');

  const auth = getAuth();
  const user = auth.currentUser;

  const reauthenticate = (currentPassword) => {
    const credential = EmailAuthProvider.credential(user.email, currentPassword);
    return reauthenticateWithCredential(user, credential);
  };

  const handleChangePassword = async (e) => {
    e.preventDefault();
    
    if (!currentPassword || !newPassword) {
      setError('Please fill out both fields.');
      return;
    }

    try {
      await reauthenticate(currentPassword); // Reauthenticate the user
      await updatePassword(user, newPassword); // Update the password
      setMessage('Password updated successfully.');
      setError(''); // Clear any previous errors
    } catch (err) {
      if (err.code === 'auth/wrong-password') {
        setError('Current password is incorrect.');
      } else if (err.code === 'auth/requires-recent-login') {
        setError('Please log in again to change your password.');
      } else {
        setError('Error updating password: ' + err.message);
      }
    }
  };

  return (
    <div className="change-password-container">
      <div className="change-password-box">
        <h1 className="brand-name">BlackBox Big Data</h1>
        <h2 className="change-password-title">Change Password</h2>
        <form onSubmit={handleChangePassword}>
          <input
            type="password"
            placeholder="Current Password"
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
            className="change-password-input"
            required
          />
          <input
            type="password"
            placeholder="New Password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            className="change-password-input"
            required
          />
          <button type="submit" className="change-password-button">Update Password</button>
        </form>
        {error && <p className="error-message">{error}</p>}
        {message && <p className="success-message">{message}</p>}
      </div>
    </div>
  );
};

export default ChangePassword;
