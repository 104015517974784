import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { db } from '../firebaseConfig'; // Assuming you have a firebase.js for config
import { collection, getDocs } from 'firebase/firestore'; // Firestore imports

// Styling remains the same
const CoursesContainer = styled.div`
  padding: 50px 20px;
  background: linear-gradient(135deg, #6A00F4, #00E1FF);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  box-sizing: border-box;
`;

const CourseCard = styled.div`
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 20px;
  width: 100%;
  max-width: 320px; /* Limit the maximum width */
  height: 450px; /* Fixed height for uniformity */
  overflow: hidden;
  transition: transform 0.2s;
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Ensures content is spaced within */
  
  &:hover {
    transform: translateY(-10px);
  }
`;

const CourseImage = styled.img`
  width: 100%;
  height: 180px;
  object-fit: cover;
`;

const CourseContent = styled.div`
  padding: 20px;
  text-align: center;
  flex-grow: 1; /* Allows content to grow within fixed height */
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const CourseTitle = styled.h3`
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 10px;
`;

const CourseDetails = styled.p`
  color: #777;
  font-size: 1rem;
  margin: 5px 0;
  flex-grow: 1; /* Allows details to adjust based on text length */
`;

const LearnMoreButton = styled.button`
  background-color: #ffd700;
  color: #333;
  padding: 10px 20px;
  border-radius: 10px;
  border: none;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  margin-top: 10px;
  align-self: center;

  &:hover {
    background-color: #ffb400;
  }
`;

const CourseList = () => {
  const [courses, setCourses] = useState([]); // State for storing fetched courses
  const navigate = useNavigate();

  useEffect(() => {
    // Function to fetch courses from Firestore
    const fetchCourses = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'courses')); // Replace 'courses' with your collection name
        const coursesData = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setCourses(coursesData);
      } catch (error) {
        console.error('Error fetching courses:', error);
      }
    };

    fetchCourses(); // Call fetch function
  }, []);

  const handleLearnMoreClick = (courseId) => {
    navigate(`/courses/${courseId}`); // Navigate to register page when "Learn More" is clicked
  };

  return (
    <CoursesContainer>
      {courses.map((course, index) => (
        <CourseCard key={index}>
          <CourseImage src={course.image} alt={course.title} />
          <CourseContent>
            <CourseTitle>{course.title}</CourseTitle>
            <CourseDetails>{course.description}</CourseDetails>
            <CourseDetails>{course.duration} hrs.</CourseDetails>
            <LearnMoreButton onClick={() => handleLearnMoreClick(course.id)}>
              Learn More
            </LearnMoreButton>
          </CourseContent>
        </CourseCard>
      ))}
    </CoursesContainer>
  );
};

export default CourseList;
