import React from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';
import CountryFlag from 'react-country-flag';
import innovaLogo from '../assets/images/innova.png';  // Import client logos
import olimpobetLogo from '../assets/images/olimpo.png';
import vikingaLogo from '../assets/images/vikinga.png';
import 'slick-carousel/slick/slick.css'; 
import 'slick-carousel/slick/slick-theme.css';

// Styled components
const CarouselContainer = styled.div`
  width: 70%;  // Reduce the size of the carousel
  margin: 50px auto;
`;

const ClientCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;  // Center the logo vertically
  align-items: center;
  padding: 20px;
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);  // Shadow for emphasis
  height: 250px;  // Adjusted height
  text-align: center;
  position: relative;  // Position text at the bottom
`;

const ClientLogo = styled.img`
  max-width: 170px;
  max-height: 170px;
  object-fit: contain;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);  // Center the logo
`;

const ClientName = styled.div`
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  font-family: 'Arial', sans-serif;
  font-weight: bold;
  color: #4D1DB3;  // Blue color for names

  .flag-icon {
    margin-right: 10px;  // Space between the flag and name
  }
`;

const ClientsCarousel = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const clients = [
    { id: 1, name: 'Innova Peru', logo: innovaLogo, flag: <CountryFlag countryCode="PE" svg className="flag-icon" /> },  // Peru flag
    { id: 2, name: 'OlimpoBet', logo: olimpobetLogo, flag: <CountryFlag countryCode="PE" svg className="flag-icon" /> },   // Mexico flag
    { id: 3, name: 'Vikinga', logo: vikingaLogo, flag: <CountryFlag countryCode="CA" svg className="flag-icon" /> },       // Canada flag
    { id: 4, name: 'Innova Mexico', logo: innovaLogo, flag: <CountryFlag countryCode="MX" svg className="flag-icon" /> },
  ];

  return (
    <CarouselContainer>
      <Slider {...settings}>
        {clients.map((client) => (
          <ClientCard key={client.id}>
            <ClientLogo src={client.logo} alt={client.name} />
            <ClientName>
              {client.flag}  {/* Render the flag icon */}
              {client.name}
            </ClientName>
          </ClientCard>
        ))}
      </Slider>
    </CarouselContainer>
  );
};

export default ClientsCarousel;
