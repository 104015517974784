import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { doc, setDoc, getDoc } from 'firebase/firestore'; // Firestore integration
import { onAuthStateChanged } from 'firebase/auth'; // Firebase Auth integration
import { db, auth } from '../firebaseConfig'; // Firebase configuration
import PhoneInput from 'react-phone-input-2'; // Import PhoneInput for country selector
import 'react-phone-input-2/lib/style.css'; // Import PhoneInput styles

// Modal components
const Modal = styled.div`
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  overflow: auto;
`;

const ModalContent = styled.div`
  background-color: #ffffff; /* Light purple background */
  color: #7f00ff; /* Purple text */
  margin: 15% auto;
  padding: 20px;
  border-radius: 8px;
  width: 80%;
  max-width: 600px;
`;

const CloseButton = styled.span`
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;

  &:hover, &:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
`;

const PayButton = styled.button`
  background-color: #ffd700;
  color: #333;
  padding: 10px 20px;
  border-radius: 10px;
  border: none;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;

  &:hover {
    background-color: #ffb400;
  }
`;

// Container for the entire registration section
const RegistrationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  background: linear-gradient(135deg, #7f00ff, #00b4d8);
  min-height: calc(100vh - 70px);
  color: #fff;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }
`;

// Updated styles for the form to match the right side
const FormContainer = styled.div`
  width: 45%;
  background: #6f00d7;
  color: white;
  padding: 40px 30px;
  border-radius: 20px;
  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.15);
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;

  @media (max-width: 768px) {
    width: 80%;
    margin-bottom: 20px;
  }
`;

const FormTitle = styled.h2`
  font-size: 2rem;
  color: #fff;
  margin-bottom: 30px;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border-radius: 8px;
  border: 2px solid #ffd700;
  font-size: 16px;
  background-color: #fff;
  color: #333;

  &::placeholder {
    color: #aaa;
  }

  &:focus {
    outline: none;
    border-color: #ffb400;
    box-shadow: 0px 0px 5px #ffd700;
  }

  &:disabled {
    background-color: #e0e0e0; /* Gray background when disabled */
  }
`;

const Button = styled.button`
  width: 100%;
  padding: 15px;
  margin-top: 20px;
  background-color: #ffd700;
  color: #333;
  border-radius: 10px;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  border: none;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #ffb400;
  }

  &:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
`;

const RightSide = styled.div`
  width: 45%;
  background-color: #6f00d7;
  color: white;
  padding: 30px 20px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin: 0 auto;

  @media (max-width: 768px) {
    width: 80%;
    margin-bottom: 20px;
  }
`;

const CoursesText = styled.h2`
  font-size: 2rem;
  color: #fff;
`;

const Subtitle = styled.p`
  font-size: 1.2rem;
  color: #ffd700;
`;

const CourseList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 20px 0;

  li {
    margin-bottom: 10px;
    font-size: 1.1rem;
    display: flex;
    align-items: center;
    justify-content: center;

    &:before {
      content: "•";
      color: yellow;
      font-size: 1.5rem;
      margin-right: 8px;
    }
  }
`;

const LearnMoreButton = styled.button`
  background-color: #ffd700;
  border: none;
  border-radius: 20px;
  padding: 12px 20px;
  font-size: 16px;
  font-weight: bold;
  color: #333;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #ffb400;
  }
`;

const RegisterPage = () => {
  const [masterClass, setMasterClass] = useState({
    name: "",
    email: "",
    phone: "",
  });
  const [isSubmitted, setIsSubmitted] = useState(false); // To disable inputs after submission
  const [userId, setUserId] = useState(null); // Store the user's UID
  const [showModal, setShowModal] = useState(false); // Modal visibility

  // Listen for the authenticated user and check for existing registration
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setUserId(user.uid); // Set the UID when user is authenticated
        console.log("user.id : " + user.uid);

        // Check if the user is already registered
        const docRef = doc(db, "masterclass", user.uid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          // If user is already registered, disable the form and fill data
          setMasterClass(docSnap.data());
          setIsSubmitted(true);
        }
      } else {
        setUserId(null); // No user is signed in
      }
    });
    return () => unsubscribe(); // Cleanup the listener on unmount
  }, []);

  const handleMasterClassSubmit = async (e) => {
    e.preventDefault();

    if (!userId) {
      console.error("No user authenticated. Cannot save the form.");
      return;
    }

    try {
      console.log("Preparing to write to Firestore for userId:", userId);

      // Save form data in Firestore under the collection "masterclass" with the UID as document ID
      await setDoc(doc(db, "masterclass", userId), {
        name: masterClass.name,
        email: masterClass.email,
        phone: masterClass.phone,
        registeredAt: new Date(),
      });

      // Set form as submitted to disable inputs
      setIsSubmitted(true);
      console.log("Master Class Registration:", masterClass);
    } catch (error) {
      console.error("Error registering in Firestore:", error);
    }
  };

  const handlePhoneChange = (value) => {
    setMasterClass({ ...masterClass, phone: value });
  };

  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);
  const goToPaymentPage = () => {
    // Redirect to payment page (implement the actual redirection logic)
    window.location.href = '/payment'; // Adjust this path to your payment page
  };

  return (
    <RegistrationContainer>
      {/* Master Class Form */}
      <FormContainer>
        <FormTitle>Register for Master Class (9th November)</FormTitle>
        <form onSubmit={handleMasterClassSubmit}>
          <Input
            type="text"
            placeholder="Your Name"
            value={masterClass.name}
            onChange={(e) => setMasterClass({ ...masterClass, name: e.target.value })}
            required
            disabled={isSubmitted} // Disable input if already registered
          />
          <Input
            type="email"
            placeholder="Your Email"
            value={masterClass.email}
            onChange={(e) => setMasterClass({ ...masterClass, email: e.target.value })}
            required
            disabled={isSubmitted} // Disable input if already registered
          />

          {/* Phone Input with Country Flag */}
          <PhoneInput
            country={'us'} // Default country
            value={masterClass.phone}
            onChange={handlePhoneChange}
            inputProps={{
              name: 'phone',
              required: true,
              disabled: isSubmitted, // Disable input if already registered
            }}
            inputStyle={{
              width: '100%', // Ensure full width is used
              paddingLeft: '90px', // Add extra padding for more space between flag and number
              padding: '10px',
              borderRadius: '8px',
              border: '2px solid #ffd700',
              fontSize: '16px',
              backgroundColor: '#fff',
              color: '#333',
            }}
            containerStyle={{
              width: '100%',
            }}
            dropdownStyle={{
              color: 'black', // Ensure country names are black
            }}
          />

          <Button type="submit" disabled={isSubmitted}>
            {isSubmitted ? "Registered" : "Register Now"}
          </Button>
        </form>
      </FormContainer>

      {/* Right Side Design (Courses Info) */}
      <RightSide>
        <CoursesText>Online Learning & Course Design</CoursesText>
        <Subtitle>Up to 50% Scholarship</Subtitle>
        <p>Course of Data Engineer.</p>
        <CourseList>
          <li>Python and PySpark</li>
          <li>Apache Flink</li>
          <li>Apache Kafka</li>
          <li>Apache Spark</li>
          <li>Apache Airflow</li>
        </CourseList>
        <LearnMoreButton onClick={openModal}>Learn More</LearnMoreButton>
      </RightSide>

      {/* Conditionally render the modal */}
      {showModal && (
        <Modal>
          <ModalContent>
            <CloseButton onClick={closeModal}>&times;</CloseButton>
            <h2>Course Descriptions</h2>
            <p><strong>Apache Kafka:</strong> A distributed event streaming platform capable of handling real-time data feeds. Ideal for big data applications.</p>
            <p><strong>Apache Flink:</strong> A framework and distributed processing engine for stateful computations over data streams.</p>
            <p><strong>Apache Spark:</strong> A powerful analytics engine for big data processing, capable of batch and streaming data analysis.</p>
            <p><strong>Apache Airflow:</strong> A platform to programmatically author, schedule, and monitor workflows.</p>
            <p><strong>Course Cost:</strong> $3000.00</p>
            <p><strong>First-Time Discount:</strong> 50% off, so the total is $1500.00!</p>
            <PayButton onClick={goToPaymentPage}>Proceed to Payment</PayButton>
          </ModalContent>
        </Modal>
      )}
    </RegistrationContainer>
  );
};

export default RegisterPage;
