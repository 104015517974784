import React from 'react';
import styled from 'styled-components';

// Styled components for layout
const AboutUsContainer = styled.div`
  padding: 50px 10%;
  background-color: #f9f9f9;
`;

const Section = styled.div`
  margin-bottom: 50px;
`;

const HeroSection = styled.div`
  text-align: center;
  padding: 50px 0;
  background-color: #6c63ff;  /* A nice light purple color */
  color: white;
`;

const HeroHeading = styled.h1`
  font-size: 3rem;
  font-weight: bold;
`;

const Tagline = styled.p`
  font-size: 1.2rem;
  margin-top: 10px;
`;

const Heading = styled.h2`
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 20px;
  color: #333;
`;

const Paragraph = styled.p`
  font-size: 1.1rem;
  line-height: 1.6;
  color: #555;
  max-width: 900px;
  margin: 0 auto;
  text-align: center;
`;

const CoreValues = styled.div`
  display: flex;
  justify-content: space-around;
  text-align: center;
`;

const ValueItem = styled.div`
  width: 30%;
`;

const ValueIcon = styled.div`
  font-size: 3rem;
  color: #6c63ff;  /* Same purple color for consistency */
`;


const CallToAction = styled.div`
  text-align: center;
  padding: 50px 0;
`;

const CTAButton = styled.button`
  padding: 10px 20px;
  font-size: 1.2rem;
  color: white;
  background-color: #6c63ff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: #584ab7;
  }
`;

const AboutUs = () => {
  return (
    <AboutUsContainer>
      
      {/* Hero Section */}
      <HeroSection>
        <HeroHeading>Who We Are</HeroHeading>
        <Tagline>Building the future with innovation and passion.</Tagline>
      </HeroSection>

      {/* Our Story Section */}
      <Section>
        <Heading>Our Journey</Heading>
        <Paragraph>
          Founded in 2023, we began with a vision to revolutionize how companies leverage data to drive growth. 
          Starting from humble beginnings, we've grown into a dedicated team of professionals who believe in 
          the power of innovation and hard work.
        </Paragraph>
      </Section>

      {/* Our Mission Section */}
      <Section>
        <Heading>Our Mission</Heading>
        <Paragraph>
          Our mission is to provide innovative data-driven solutions that help businesses make smarter decisions, 
          improve efficiency, and achieve sustainable growth.
        </Paragraph>
      </Section>

      {/* Core Values Section */}
      <Section>
        <Heading>Our Core Values</Heading>
        <CoreValues>
          <ValueItem>
            <ValueIcon>🚀</ValueIcon>
            <h3>Innovation</h3>
            <Paragraph>We constantly push the boundaries of what’s possible to deliver cutting-edge solutions.</Paragraph>
          </ValueItem>
          <ValueItem>
            <ValueIcon>🤝</ValueIcon>
            <h3>Collaboration</h3>
            <Paragraph>We believe in working closely with our clients to ensure their success.</Paragraph>
          </ValueItem>
          <ValueItem>
            <ValueIcon>🔒</ValueIcon>
            <h3>Integrity</h3>
            <Paragraph>We uphold the highest standards of integrity in all our work.</Paragraph>
          </ValueItem>
        </CoreValues>
      </Section>

      {/* Call to Action */}
      <CallToAction>
        <Heading>Work With Us</Heading>
        <Paragraph>Interested in working with us? We’d love to hear from you.</Paragraph>
        <CTAButton>Contact Us</CTAButton>
      </CallToAction>

    </AboutUsContainer>
  );
};

export default AboutUs;
