import React from 'react';
import styled from 'styled-components';
import { FaBars, FaTimes, FaHome, FaProductHunt, FaInfoCircle, FaPhone, FaSignInAlt, FaUserPlus, FaUserCircle, FaSignOutAlt, FaBookOpen } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';  // For routing

const NavbarContainer = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  max-width: 100%;
  margin: 0;
  height: 100%;
  background: transparent;
`;

const Logo = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
  color: white;
  display: flex;
  align-items: center;

  span {
    color: #00c3e6;
  }
`;

const NavLinks = styled.ul`
  display: flex;
  list-style: none;

  @media (max-width: 768px) {
    position: fixed;
    top: 0;
    left: ${({ open }) => (open ? '0' : '-100%')};
    height: 100vh;
    width: 100%;
    background-color: #6001d3;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease-in-out;
  }
`;

const NavLink = styled.li`
  margin: 0 15px;
  font-size: 1rem;
  font-weight: 500;
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: color 0.3s;

  &:hover {
    color: #00c3e6;
  }

  svg {
    margin-right: 8px;
    font-size: 1rem; /* Set icon size the same as text */
    color: white;
  }

  a {
    text-decoration: none;
    display: flex;
    align-items: center;
  }

  button {
    text-decoration: none;
    display: flex;
    align-items: center;
    background: none;
    border: none;
    color: inherit;
    cursor: pointer;
    font-size: inherit;
  }

  @media (max-width: 768px) {
    margin: 20px 0;
    font-size: 1.5rem;

    svg {
      margin-right: 10px;
    }
  }
`;

const MenuIcon = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: block;
    color: white;
    font-size: 1.8rem;
    cursor: pointer;
  }
`;

const ProfileDropdown = styled.div`
  position: relative;
  display: inline-block;

  .dropdown-content {
    display: none;
    position: absolute;
    right: 0;
    background-color: #6001d3;
    min-width: 160px;
    z-index: 1;
    border-radius: 8px;
    padding: 10px;
  }

  &:hover .dropdown-content {
    display: block;
  }

  a {
    color: white;
    padding: 10px;
    text-decoration: none;
    display: block;
    text-align: left;
    transition: color 0.3s;

    &:hover {
      color: #00c3e6;
    }
  }
`;

const Navbar = ({ isAuthenticated, setIsAuthenticated }) => {
  console.log('Navbar isAuthenticated:', isAuthenticated);
  const [open, setOpen] = React.useState(false);  // State to manage mobile menu open/close
  const navigate = useNavigate();

  const toggleMenu = () => {
    setOpen(!open);
  };

  const handleLogout = () => {
    setIsAuthenticated(false);  // Log out the user
    localStorage.removeItem('isAuthenticated');
    navigate("/login");  // Redirect to login page after logout
  };

  return (
    <NavbarContainer>
      <Logo>
        <span>Black</span>Box Big Data
      </Logo>
      <NavLinks open={open}>
        <NavLink>
          <Link to="/" onClick={toggleMenu}>
            <FaHome /> Home
          </Link>
        </NavLink>
        <NavLink>
          <Link to="/services" onClick={toggleMenu}>
            <FaProductHunt /> Services
          </Link>
        </NavLink>
        <NavLink>
          <Link to="/about" onClick={toggleMenu}>
            <FaInfoCircle /> About
          </Link>
        </NavLink>
        <NavLink>
          <Link to="/contact" onClick={toggleMenu}>
            <FaPhone /> Contact
          </Link>
        </NavLink>

        {/* Render based on authentication status */}
        {isAuthenticated ? (
          <>
            <NavLink>
              <Link to="/courses" onClick={toggleMenu}>
                <FaBookOpen /> Courses
              </Link>
            </NavLink>
            <NavLink>
              <button onClick={handleLogout}>
                <FaSignOutAlt /> Log Out
              </button>
            </NavLink>
            <ProfileDropdown>
              <FaUserCircle style={{ fontSize: "1.5rem", cursor: "pointer", color: "white" }} />
              <div className="dropdown-content">
                <Link to="/profile" onClick={toggleMenu}>Profile</Link>
                <Link to="/changepassword" onClick={toggleMenu}>Change Password</Link>
                <Link to="/coursesregistered" onClick={toggleMenu}>Courses Registered</Link>
              </div>
            </ProfileDropdown>
          </>
        ) : (
          <>
            <NavLink>
              <Link to="/login" onClick={toggleMenu}>
                <FaSignInAlt /> Login
              </Link>
            </NavLink>
            <NavLink>
              <Link to="/signup" onClick={toggleMenu}>
                <FaUserPlus /> Sign Up
              </Link>
            </NavLink>
          </>
        )}
      </NavLinks>
      <MenuIcon onClick={toggleMenu}>
        {open ? <FaTimes /> : <FaBars />}
      </MenuIcon>
    </NavbarContainer>
  );
};

export default Navbar;
