import React, { useState } from 'react';
import { createUserWithEmailAndPassword } from "firebase/auth";
import { auth } from "../firebaseConfig";  // Import Firebase auth object
import { useNavigate } from "react-router-dom";  // For redirection

const SignUp = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();  // Create navigate instance for redirect

  // Handle Sign Up
  const handleSignUp = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }
    try {
      // Firebase sign up with email and password
      await createUserWithEmailAndPassword(auth, email, password);
      alert("User created successfully!");
      navigate("/courses");  // Redirect to Courses page after sign-up
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.logoText}>BlackBox Big Data</h1>
      <h2 style={styles.title}>Sign Up</h2>
      {error && <p style={styles.errorText}>{error}</p>}
      
      <form onSubmit={handleSignUp} style={styles.form}>
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          style={styles.input}
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
          style={styles.input}
        />
        <input
          type="password"
          placeholder="Confirm Password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          required
          style={styles.input}
        />
        
        <button type="submit" style={styles.signupButton}>Sign Up</button>
      </form>

      <p style={styles.loginText}>Already have an account? 
        <a href="/login" style={styles.loginLink}> Login here</a>
      </p>
    </div>
  );
};

// Styles object for a cohesive look with the Login page
const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "100vh",
    background: "linear-gradient(135deg, #7f00ff, #00b4d8)", // Same gradient as the Login page
    padding: "20px",
  },
  logoText: {
    fontSize: "32px",
    fontWeight: "bold",
    color: "#fff",
    marginBottom: "40px",
  },
  title: {
    fontSize: "24px",
    color: "#fff",
    marginBottom: "20px",
  },
  form: {
    width: "100%",
    maxWidth: "400px",
    display: "flex",
    flexDirection: "column",
    marginBottom: "20px",
  },
  input: {
    backgroundColor: "#fff",
    borderRadius: "10px",
    padding: "12px",
    marginBottom: "10px",
    fontSize: "16px",
    width: "100%",
    boxSizing: "border-box",
    border: "none",
  },
  signupButton: {
    backgroundColor: "#673ab7", // Purple button
    padding: "15px 0",
    borderRadius: "10px",
    color: "#fff",
    fontSize: "18px",
    fontWeight: "bold",
    border: "none",
    cursor: "pointer",
    transition: "background-color 0.3s ease",
  },
  errorText: {
    color: "red",
    marginBottom: "10px",
  },
  loginText: {
    color: "#f0f0f0",
    textAlign: "center",
    marginTop: "20px",
  },
  loginLink: {
    color: "#673ab7",  // Same purple as the buttons
    textDecoration: "none",
    marginLeft: "5px",
  },
};

export default SignUp;
