import { useState, useEffect } from 'react';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { FaInfoCircle } from 'react-icons/fa';
import '../assets/Modal.css';

const CoursesRegistered = () => {
  const [, setUser] = useState(null);
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const auth = getAuth();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        fetchCourses(currentUser);
      } else {
        setUser(null);
        setLoading(false);
      }
    });

    return () => unsubscribe();
  }, [auth, setUser]);

  const fetchCourses = async (currentUser) => {
    try {
      const q = query(
        collection(db, 'orders'),
        where('userId', '==', currentUser.uid)
      );
      const querySnapshot = await getDocs(q);
      const coursesData = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      console.log("Fetched Courses:", coursesData);
      setCourses(coursesData);
    } catch (error) {
      console.error("Error fetching courses:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleShowModal = (course) => {
    setSelectedCourse(course);
    setShowModal(true);
  };

  if (loading) {
    return <div>Loading courses...</div>;
  }

  return (
    <div style={styles.pageContainer}>
      <div style={styles.contentContainer}>
        <h2 style={styles.header}>Registered Courses</h2>
        <CoursesTable courses={courses} onInfoClick={handleShowModal} />
        {showModal && <CourseInfoModal course={selectedCourse} onClose={() => setShowModal(false)} />}
      </div>
      <Footer />
    </div>
  );
};

const CoursesTable = ({ courses, onInfoClick }) => {
  if (courses.length === 0) {
    return <p>No courses registered yet.</p>;
  }

  return (
    <table style={styles.table}>
      <thead>
        <tr>
          <th style={styles.tableHeader}>Course Name</th>
          <th style={styles.tableHeader}>Payment Date</th>
          <th style={styles.tableHeader}>Amount Paid</th>
          <th style={styles.tableHeader}>Info</th>
        </tr>
      </thead>
      <tbody>
        {courses.map((course, index) => (
          <tr key={index} style={index % 2 === 0 ? styles.tableRowEven : styles.tableRowOdd}>
            <td>{course.courseTitle || 'No Course Name'}</td>
            <td>
              {course.timestamp
                ? course.timestamp.toDate().toLocaleDateString()
                : 'No Payment Date'}
            </td>
            <td>{course.courseCost ? `$${course.courseCost}` : 'No Amount Paid'}</td>
            <td>
              <FaInfoCircle
                style={styles.icon}
                onClick={() => onInfoClick(course)}
              />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const CourseInfoModal = ({ onClose }) => {
  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>Information</h2>
        <p>The link for the course will be sent one day before the class via email.</p>
        <button className="close-modal-button" onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

// Footer Component
const Footer = () => (
  <footer style={styles.footer}>
    © 2024 BlackBox Big Data. All Rights Reserved.
  </footer>
);

// Inline styles for the layout, table, and footer
const styles = {
  pageContainer: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh', // Ensures footer is at the bottom of the viewport
  },
  contentContainer: {
    flex: 1, // Takes up remaining space, pushing footer to the bottom
    background: 'linear-gradient(90deg, #5f2cff 0%, #00b9f2 100%)',
    padding: '20px',
    borderRadius: '10px',
    color: 'black',
    margin: '20px',
  },
  header: {
    textAlign: 'center',
    marginBottom: '20px',
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
  },
  tableHeader: {
    padding: '12px 15px',
    backgroundColor: '#4CAF50',
    color: 'white',
  },
  tableRowEven: {
    backgroundColor: '#f2f2f2',
  },
  tableRowOdd: {
    backgroundColor: 'white',
  },
  icon: {
    cursor: 'pointer',
    color: '#4CAF50',
    fontSize: '1.5rem',
  },
  footer: {
    textAlign: 'center',
    padding: '15px 0',
    background: 'linear-gradient(135deg, #7f00ff, #00b4d8)',
    color: 'white',
    fontSize: '0.9rem',
    borderTop: '2px solid #4CAF50',
  },
};

export default CoursesRegistered;
