import React from 'react';
import ClientsCarousel from '../components/ClientsCarousel';  // Import the carousel component

const Home = () => {
  return (
    <>
      <ClientsCarousel />  {/* Add the carousel here */}
    </>
  );
};

export default Home;