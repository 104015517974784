import React from 'react';
import styled from 'styled-components';

// Footer container
const FooterContainer = styled.footer`
  background: linear-gradient(135deg, #6A00F4, #00B9F2);
  color: #fff;
  padding: 40px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  box-sizing: border-box;

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
  }
`;

// Copyright section
const Copyright = styled.div`
  margin-top: 20px;
  text-align: center;
  width: 100%;
  font-size: 0.9rem;
  color: #ddd;
`;

const Footer = () => {
  return (
    <FooterContainer>
      <Copyright>
        &copy; {new Date().getFullYear()} BlackBox Big Data. All Rights Reserved.
      </Copyright>
    </FooterContainer>
  );
};

export default Footer;
