// ProtectedRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';

// Protected Route component that checks if a user is authenticated
const ProtectedRoute = ({ isAuthenticated, children }) => {
  if (!isAuthenticated) {
    // If user is not authenticated, redirect to login
    return <Navigate to="/login" replace />;
  }

  // If user is authenticated, render the protected component (e.g., Courses)
  return children;
};

export default ProtectedRoute;
