import React from 'react';
import styled from 'styled-components';
import Navbar from './Navbar';  // Import Navbar

const HeaderContainer = styled.header`
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 999;
  background: linear-gradient(135deg, #6001d3, #00c3e6);
  padding: 0;
  margin: 0;
  box-sizing: border-box;
`;

const Header = ({ isAuthenticated, setIsAuthenticated }) => {  // Receive props from App.js
  return (
    <HeaderContainer>
      <Navbar isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} />  {/* Pass props to Navbar */}
    </HeaderContainer>
  );
};

export default Header;
