import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { getDoc, updateDoc, doc, collection, setDoc } from 'firebase/firestore'; // Firestore integration
import { onAuthStateChanged } from 'firebase/auth'; // Firebase Auth integration
import { db, auth } from '../firebaseConfig'; // Firebase configuration
import PhoneInput from 'react-phone-input-2'; // Import PhoneInput for country selector
import 'react-phone-input-2/lib/style.css'; // Import PhoneInput styles
import { useParams } from 'react-router-dom';

const SaveButtonRight = styled.button`
  background-color: #ffd700;
  color: #333;
  padding: 10px 20px;
  border-radius: 10px;
  border: none;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;

  &:hover {
    background-color: #ffb400;
  }
`;

const Message = ({ message }) => (
  <section>
    <p>{message}</p>
  </section>
);

// Container for the entire registration section
const RegistrationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  background: linear-gradient(135deg, #7f00ff, #00b4d8);
  min-height: calc(100vh - 70px);
  color: #fff;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }
`;

// Updated styles for the form to match the right side
const FormContainer = styled.div`
  width: 45%;
  background: #6f00d7;
  color: white;
  padding: 40px 30px;
  border-radius: 20px;
  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.15);
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;

  @media (max-width: 768px) {
    width: 80%;
    margin-bottom: 20px;
  }
`;

const FormTitle = styled.h2`
  font-size: 2rem;
  color: #fff;
  margin-bottom: 30px;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border-radius: 8px;
  border: 2px solid #ffd700;
  font-size: 16px;
  background-color: #fff;
  color: #333;

  &::placeholder {
    color: #aaa;
  }

  &:focus {
    outline: none;
    border-color: #ffb400;
    box-shadow: 0px 0px 5px #ffd700;
  }

  &:disabled {
    background-color: #e0e0e0; /* Gray background when disabled */
  }
`;

const Button = styled.button`
  width: 100%;
  padding: 15px;
  margin-top: 20px;
  background-color: #ffd700;
  color: #333;
  border-radius: 10px;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  border: none;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #ffb400;
  }

  &:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
`;

const RightSide = styled.div`
  width: 45%;
  background-color: #6f00d7;
  color: white;
  padding: 30px 20px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin: 0 auto;

  @media (max-width: 768px) {
    width: 80%;
    margin-bottom: 20px;
  }
`;

const CoursesText = styled.h2`
  font-size: 2rem;
  color: #fff;
`;

const Subtitle = styled.p`
  font-size: 1.2rem;
  color: #ffd700;
  font-weight: bold;
`;

const CourseList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 20px 0;

  li {
    margin-bottom: 10px;
    font-size: 1.1rem;
    display: flex;
    align-items: center;
    justify-content: center;

    &:before {
      content: "•";
      color: yellow;
      font-size: 1.5rem;
      margin-right: 8px;
    }
  }
`;

const CourseDetail = () => {
  const [masterClass, setMasterClass] = useState({
    name: "",
    email: "",
    phone: "",
  });
  const [isSubmitted, setIsSubmitted] = useState(false); // To disable inputs after submission
  const [userId, setUserId] = useState(null); // Store the user's UID
  const [masterClassDate, setMasterClassDate] = useState("");
  const [courseTitle, setTitleCourse] = useState("");
  const [courseCost, setCourseCost] = useState("");
  const [scholarshipCourse, setScholarshipCourse] = useState("");
  const [descriptionCourse, setDescriptionCourse] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const { courseId } = useParams(); 

  // Listen for the authenticated user and check for existing registration
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setUserId(user.uid); // Set the UID when user is authenticated
  
        // Reference to the document
        const docRef = doc(db, "masterclass", user.uid);
        const docSnap = await getDoc(docRef);
  
        if (docSnap.exists()) {
          const data = docSnap.data();
          console.log("Document data:", data);
  
          if (data.courses && Array.isArray(data.courses)) {
            console.log("Courses array:", data.courses);
            // Check if user is already registered
            const course = data.courses.find(c => c.courseId === courseId);
  
            if (course) {
              // User is already registered for this course
              setMasterClass(course); // Set the course-specific data
              setIsSubmitted(true);
              console.log(`User is already registered for course ${courseId}`);
            } else {
              console.log("No registration found for this course and user.");
            }
          } else {
            console.log("Courses field is not an array or is missing.");
          }
        } else {
          console.log("No document found for this user.");
        }
      } else {
        setUserId(null); // No user is signed in
      }
    });
    return () => unsubscribe(); // Cleanup the listener on unmount
  }, [courseId]);

   // Fetch the masterClassDate from the "courses" collection
   useEffect(() => {
    const fetchCourseData = async () => {
      try {
        const courseDocRef = doc(db, "courses", courseId);
        const courseDocSnap = await getDoc(courseDocRef);

        if (courseDocSnap.exists()) {
          const courseData = courseDocSnap.data();
          setMasterClassDate(courseData.masterClassDate || ""); // Set the masterClassDate or default to empty string
          setTitleCourse(courseData.title || "");
          setScholarshipCourse(courseData.scholarship || "");
          setCourseCost(courseData.costo || "");
          setDescriptionCourse(courseData.description || "");
        } else {
          console.log("No course data found for this courseId.");
        }
      } catch (error) {
        console.error("Error fetching course data:", error);
      }
    };

    fetchCourseData();
  }, [courseId]);

  const handleMasterClassSubmit = async (e) => {
    e.preventDefault();

    if (!userId) {
      console.error("No user authenticated. Cannot save the form.");
      return;
    }

    try {
      console.log("Preparing to write to Firestore for userId:", userId);

        // Reference to the document
        const docRef = doc(db, "masterclass", userId);

        // Get the current document snapshot
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            // Document exists, so get the current courses array
            const currentCourses = docSnap.data().courses || [];
      
            // Append the new course object
            const updatedCourses = [
              ...currentCourses,
              {
                courseId: courseId,
                name: masterClass.name,
                email: masterClass.email,
                phone: masterClass.phone,
                registeredAt: new Date(),
              },
            ];
      
            // Update the document with the new courses array
            await updateDoc(docRef, {
              courses: updatedCourses,
            });
            console.log(`Course ${courseId} added to existing courses array`);
          }

      // Set form as submitted to disable inputs
      setIsSubmitted(true);
      console.log("Master Class Registration:", masterClass);
    } catch (error) {
      console.error("Error registering in Firestore:", error);
    }
  };

  const handlePhoneChange = (value) => {
    setMasterClass({ ...masterClass, phone: value });
  };

  const handlePayment = async () => {

    try {
      setLoading(true);

      const user = auth.currentUser;
      if (!user) {
        setMessage("User is not authenticated");
        return;
      }
      const token = await user.getIdToken();

      if (!token) {
        setMessage("Failed to retrieve user authentication token. Please try again.");
        setLoading(false);
        return;
      }
      const orderNumber = Math.floor(100000 + Math.random() * 900000).toString();
      const total = Number(courseCost) * 100;

      // Step 1: Save the order information to Firestore
      const orderData = {
        name: masterClass.name,
        email: masterClass.email,
        phone: masterClass.phone,
        orderNumber,
        courseTitle,        // Course title/name
        courseCost: total / 100, // Store the original course cost in dollars
        userId: user.uid,
        timestamp: new Date(),
      };

      // Create a new document in the "orders" collection in Firestore
      const orderRef = doc(collection(db, 'orders'), orderNumber); // Using orderNumber as the document ID for easy lookup
      await setDoc(orderRef, orderData);
      console.log("Order information saved successfully:", orderData);


      const response = await fetch(
        "https://us-central1-blackboxbigdata-c87f6.cloudfunctions.net/api/create-checkout-session",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`,
          },
          body: JSON.stringify({
            items: [
              {
                price_data: {
                  currency: "usd",
                  product_data: { name: `${orderNumber} - Blackbox` },
                  unit_amount: total,
                },
                quantity: 1,
              },
            ],
          }),
        }
      );

      const data = await response.json();
      if (data.url) {
        window.location.href = data.url;
      } else {
        setMessage("Error creating checkout session");
      }
    } catch (error) {
      console.error("Error during checkout:", error);
      setMessage("Failed to initiate checkout. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <RegistrationContainer>
      {/* Master Class Form */}
      <FormContainer>
        <FormTitle>Register for Master Class ({masterClassDate}) - {courseTitle}</FormTitle>
        <form onSubmit={handleMasterClassSubmit}>
          <Input
            type="text"
            placeholder="Your Name"
            value={masterClass.name}
            onChange={(e) => setMasterClass({ ...masterClass, name: e.target.value })}
            required
            disabled={isSubmitted} // Disable input if already registered
          />
          <Input
            type="email"
            placeholder="Your Email"
            value={masterClass.email}
            onChange={(e) => setMasterClass({ ...masterClass, email: e.target.value })}
            required
            disabled={isSubmitted} // Disable input if already registered
          />

          {/* Phone Input with Country Flag */}
          <PhoneInput
            country={'us'} // Default country
            value={masterClass.phone}
            onChange={handlePhoneChange}
            inputProps={{
              name: 'phone',
              required: true,
              disabled: isSubmitted, // Disable input if already registered
            }}
            inputStyle={{
              width: '100%', // Ensure full width is used
              paddingLeft: '90px', // Add extra padding for more space between flag and number
              padding: '10px',
              borderRadius: '8px',
              border: '2px solid #ffd700',
              fontSize: '16px',
              backgroundColor: '#fff',
              color: '#333',
            }}
            containerStyle={{
              width: '100%',
            }}
            dropdownStyle={{
              color: 'black', // Ensure country names are black
            }}
          />

          <Button type="submit" disabled={isSubmitted}>
            {isSubmitted ? "Registered" : "Register Now"}
          </Button>
        </form>
      </FormContainer>

      {/* Right Side Design (Courses Info) */}
      <RightSide>
        <CoursesText>Online Learning & Course Design</CoursesText>
        <Subtitle>{scholarshipCourse}</Subtitle>
        <Subtitle>{courseCost}.00 USD</Subtitle>
        <CourseList>
          {descriptionCourse}
        </CourseList>

        {loading ? (
          <SaveButtonRight>Loading...</SaveButtonRight> 
        ) : (
          <SaveButtonRight onClick={handlePayment}>Checkout</SaveButtonRight>
        )}
        
        {message && <Message message={message} />}
      </RightSide>
    </RegistrationContainer>
  );
};

export default CourseDetail;
