import React, { useState } from 'react';
import styled from 'styled-components';
import { db } from '../firebaseConfig'; // Import Firebase configuration
import { collection, addDoc } from 'firebase/firestore'; // Firestore methods

// Background and container styling
const ContactContainer = styled.div`
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;
  background: linear-gradient(135deg, #6A00F4, #00B9F2); /* Gradient background */
  color: #fff;
`;

const ContactFormWrapper = styled.div`
  background-color: #fff;
  color: #333;
  padding: 40px;
  border-radius: 20px;
  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.15);
  width: 100%;
  max-width: 600px;
  text-align: center;
`;

// Styled form elements
const FormTitle = styled.h2`
  font-size: 2rem;
  margin-bottom: 20px;
  color: #6A00F4;
`;

const FormDescription = styled.p`
  font-size: 1rem;
  margin-bottom: 20px;
  color: #555;
`;

const Input = styled.input`
  width: 100%;
  padding: 12px;
  margin-bottom: 20px;
  border-radius: 8px;
  border: 2px solid #6A00F4;
  font-size: 1rem;
  background-color: #f9f9f9;
  color: #333;

  &::placeholder {
    color: #aaa;
  }

  &:focus {
    outline: none;
    border-color: #00B9F2;
    box-shadow: 0px 0px 5px #6A00F4;
  }
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 12px;
  margin-bottom: 20px;
  border-radius: 8px;
  border: 2px solid #6A00F4;
  font-size: 1rem;
  background-color: #f9f9f9;
  color: #333;
  height: 150px;

  &::placeholder {
    color: #aaa;
  }

  &:focus {
    outline: none;
    border-color: #00B9F2;
    box-shadow: 0px 0px 5px #6A00F4;
  }
`;

const Button = styled.button`
  background-color: #6A00F4;
  color: #fff;
  padding: 12px 20px;
  border-radius: 8px;
  border: none;
  font-size: 1.1rem;
  cursor: pointer;
  font-weight: bold;

  &:hover {
    background-color: #00B9F2;
  }
`;

// Support email section
const SupportEmail = styled.p`
  margin-top: 20px;
  font-size: 1rem;
  color: #333;

  a {
    color: #6A00F4;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const [submitted, setSubmitted] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Save form data to Firebase Firestore
      await addDoc(collection(db, 'contactMessages'), {
        name: formData.name,
        email: formData.email,
        message: formData.message,
        submittedAt: new Date(), // Optional: Add timestamp
      });
      setSubmitted(true); // Show success message
    } catch (error) {
      console.error("Error saving message: ", error);
    }
  };

  return (
    <ContactContainer>
      <ContactFormWrapper>
        <FormTitle>Contact Us</FormTitle>
        {!submitted ? (
          <>
            <FormDescription>
              Have any questions or need help? Feel free to reach out to us!
            </FormDescription>
            <form onSubmit={handleSubmit}>
              <Input
                type="text"
                name="name"
                placeholder="Your Name"
                value={formData.name}
                onChange={handleChange}
                required
              />
              <Input
                type="email"
                name="email"
                placeholder="Your Email"
                value={formData.email}
                onChange={handleChange}
                required
              />
              <TextArea
                name="message"
                placeholder="Your Message"
                value={formData.message}
                onChange={handleChange}
                required
              />
              <Button type="submit">Send Message</Button>
            </form>
          </>
        ) : (
          <FormDescription>Thank you! We will get back to you shortly.</FormDescription>
        )}
        <SupportEmail>
          Or contact us directly at:{" "}
          <a href="mailto:support@blackboxbigdata.com">
            support@blackboxbigdata.com
          </a>
        </SupportEmail>
      </ContactFormWrapper>
    </ContactContainer>
  );
};

export default Contact;
