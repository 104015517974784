// CancelPage.js
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const CancelContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 80vh; /* Keeps content centered */
  text-align: center;
  padding: 20px;
  background: #000000 /*linear-gradient(135deg, #7f00ff, #00b4d8);*/
  color: #333;

  h1 {
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 10px;
  }

  p {
    font-size: 1.2rem;
    color: #555;
    margin-bottom: 20px;
  }

  a {
    font-size: 1.1rem;
    font-weight: bold;
    color: #007bff;
    text-decoration: none;
  }

  a:hover {
    color: #0056b3;
  }
`;

const Footer = styled.footer`
  width: 100%;
  text-align: center;
  padding: 15px 0;
  background: linear-gradient(135deg, #7f00ff, #00b4d8);
  color: white;
  font-size: 0.9rem;
  position: relative;
  bottom: 0;
`;

const CancelPage = () => {
  return (
    <>
      <CancelContainer>
        <h1>Pago Cancelado</h1>
        <p>Ha cancelado el proceso de pago. Si fue un error, puede intentarlo de nuevo.</p>
        <Link to="/">Return to Home</Link>
      </CancelContainer>
      <Footer>
        © 2024 BlackBox Big Data. All Rights Reserved.
      </Footer>
    </>
  );
};

export default CancelPage;
