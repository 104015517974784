// Import the necessary functions from the SDKs
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

// Your Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBqzruPO5J0yXdGq-ZKS7H_gT3Y-5fyS1E",
  authDomain: "blackboxbigdata-c87f6.firebaseapp.com",
  projectId: "blackboxbigdata-c87f6",
  storageBucket: "blackboxbigdata-c87f6",
  messagingSenderId: "178773726607",
  appId: "1:178773726607:web:75ffb0db27e297b83167fa",
  measurementId: "G-L1B8GHEXZB"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore (db)
const db = getFirestore(app);

// Initialize Firebase Authentication
const auth = getAuth(app);

// Export both Firestore (db) and Firebase app instance
export { db, app, auth };
