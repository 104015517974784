import React from 'react';
import styled from 'styled-components';

const ServicesContainer = styled.div`
  padding: 50px 20px;
  background-color: #f7f8fc;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
`;

const ServiceCard = styled.div`
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 300px;
  margin: 20px;
  padding: 30px;
  transition: transform 0.3s ease;
  &:hover {
    transform: translateY(-10px);
  }
`;

const ServiceIcon = styled.div`
  font-size: 40px;
  margin-bottom: 15px;
  color: #00c3e6;
`;

const ServiceTitle = styled.h3`
  font-size: 1.5rem;
  color: #6001d3;
  margin-bottom: 10px;
`;

const ServiceDescription = styled.p`
  font-size: 1rem;
  color: #555;
`;

const Services = () => {
  return (
    <ServicesContainer>
      <ServiceCard>
        <ServiceIcon>💻</ServiceIcon>
        <ServiceTitle>Software Migration Support</ServiceTitle>
        <ServiceDescription>
          We use thorough, risk-averse migration techniques for manual and automated data extraction, testing, and implementation.
        </ServiceDescription>
      </ServiceCard>

      <ServiceCard>
        <ServiceIcon>🌐</ServiceIcon>
        <ServiceTitle>Website Migration Services</ServiceTitle>
        <ServiceDescription>
          We have extensive experience migrating websites across various web hosting platforms, ensuring all requirements are met.
        </ServiceDescription>
      </ServiceCard>

      <ServiceCard>
        <ServiceIcon>☁️</ServiceIcon>
        <ServiceTitle>Cloud Migration Services</ServiceTitle>
        <ServiceDescription>
          We specialize in seamless migrations to cloud environments, leveraging providers like AWS, Google Cloud, and Azure.
        </ServiceDescription>
      </ServiceCard>

      <ServiceCard>
        <ServiceIcon>🗄️</ServiceIcon>
        <ServiceTitle>Database Migration Services</ServiceTitle>
        <ServiceDescription>
          We migrate multiple data types and business logic schemas using ETL best practices and data quality assessment.
        </ServiceDescription>
      </ServiceCard>
    

      <ServiceCard>
        <ServiceIcon>📊</ServiceIcon>
        <ServiceTitle>Big Data Services</ServiceTitle>
        <ServiceDescription>
          We provide cutting-edge Big Data solutions for data processing, analysis, and visualization, leveraging tools like Apache Spark and Hadoop.
        </ServiceDescription>
      </ServiceCard>

      {/* New Teach Service */}
      <ServiceCard>
        <ServiceIcon>📚</ServiceIcon>
        <ServiceTitle>Teach Services</ServiceTitle>
        <ServiceDescription>
          Our Teach services help professionals and organizations learn the latest technologies through hands-on workshops and personalized training.
        </ServiceDescription>
        </ServiceCard>
      </ServicesContainer>
  );
};

export default Services;
