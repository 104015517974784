import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';  // Import Header, which contains Navbar
import Services from './pages/Services';
import Home from './pages/Home';
import About from './pages/About';
import Login from './pages/Login';
import SignUp from './pages/SignUp';
import Courses from './pages/Courses';
import CourseDetail from './pages/CourseDetail.js';
import Register from './pages/RegisterPage';
import Payment from './pages/PaymentPage';
import CoursesRegistered from './pages/CoursesRegistered';
import ChangePassword from './pages/ChangePassword';
import Hero from './components/HeroSection';
import ProtectedRoute from './components/ProtectedRoute';
import Footer from './components/Footer';
import Contact from './pages/Contact';
import SuccessPage from './pages/SuccessPage.js';
import CancelPage from './pages/CancelPage.js';


function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(null);  // Use null for loading state
  const [loading, setLoading] = useState(true);  // Track loading state

  // Check localStorage for authentication status on mount
  useEffect(() => {
    const authState = localStorage.getItem('isAuthenticated') === 'true';
    setIsAuthenticated(authState);
    setLoading(false);  // Set loading to false once auth state is set
  }, []);

  if (loading) {
    return <div>Loading...</div>;  // Show a loading message while checking auth status
  }

  return (
    <Router>
      <Header isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} />  {/* Pass props to Header */}
      <Routes>
        <Route exact path="/" element={<><Hero /><Home /></>} />
        <Route path="/services" element={<><Hero /><Services /></>} />
        <Route path="/about" element={<><Hero /><About /></>} />
        <Route path="/login" element={<Login setIsAuthenticated={setIsAuthenticated} />} />
        <Route path="/signup" element={<SignUp />} />
        <Route
          path="/courses"
          element={
            <ProtectedRoute isAuthenticated={isAuthenticated}>
              <Courses />
            </ProtectedRoute>
          }
        />
        <Route
          path="/courses/:courseId"
          element={
            <ProtectedRoute isAuthenticated={isAuthenticated}>
              <CourseDetail />
            </ProtectedRoute>
          }
        />
        <Route
          path="/register"
          element={
            <ProtectedRoute isAuthenticated={isAuthenticated}>
              <Register />
            </ProtectedRoute>
          }
        />
        <Route
          path="/payment"
          element={
            <ProtectedRoute isAuthenticated={isAuthenticated}>
              <Payment />
            </ProtectedRoute>
          }
        />
        <Route
          path="/coursesregistered"
          element={
            <ProtectedRoute isAuthenticated={isAuthenticated}>
              <CoursesRegistered />
            </ProtectedRoute>
          }
        />
        <Route
          path="/changepassword"
          element={
            <ProtectedRoute isAuthenticated={isAuthenticated}>
              <ChangePassword />
            </ProtectedRoute>
          }
        />
        <Route path="/contact" element={<><Contact /></>} />
        <Route path="/success" element={<><SuccessPage /></>} />
        <Route path="/cancel" element={<><CancelPage /></>} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
